//STEP 0: BOOTSTRAP
console.log("setting up...")

// STEP 1: DECLARE VARIABLES

const config = {
  apiKey: "AIzaSyCDDxHZE5QvsA9aDR73L_GNnBbYgc-rlFc",
  authDomain: "jeneverrad.firebaseapp.com",
  databaseURL: "https://jeneverrad.firebaseio.com",
  projectId: "jeneverrad",
  storageBucket: "jeneverrad.appspot.com",
  messagingSenderId: "1025909346097",
  appId: "1:1025909346097:web:22f483f0a7a2ab0b62aa10",
};

var data = [];
var tempData = [];
var isTouched = false;
var prizeData = [];

// STEP 2: INITIALIZE FIREBASE

firebase.initializeApp(config);
var db = firebase.firestore();

// STEP 3: FETCH EVENT DATA

document.getElementById(
  "title-field"
).innerHTML = `Het rad van de Jeneveravond`;

// STEP 4: FETCH EVENT PRIZE DATA
db.collection(`/jeneverrad-v2/9toF2QX7S4phQ94ZLrfx/gameprizes`)
  .where("isActive", "==", true)
  .get()
  .then(function (querySnapshot) {
    querySnapshot.forEach(function (doc) {
      prizeData.push(doc.data());
    });
  });

// STEP 5: START LISTENING FOR DATA

db.collection(`/jeneverrad-v2/9toF2QX7S4phQ94ZLrfx/drinks`)
  .where("isActive", "==", true)
  .onSnapshot(function (querySnapshot) {
    tempData = [];
    querySnapshot.forEach(function (doc) {
      //push data in a temporary array
      const drink = doc.data();

      tempData.push(drink);
    });
    // add data to array when the wheel is first loaded
    if (isTouched === false) {
      data = prepareData(tempData);
      // this function does all the work and is being called back after each spin
      setWheelChart();
    }
    // avoid the code above from being run again
    isTouched = true;
  });

function setWheelChart() {

  // set chart parameters
  var padding = { top: 0, right: 40, bottom: 0, left: 0 },
    w = 800 - padding.left - padding.right,
    h = 800 - padding.top - padding.bottom,
    r = Math.min(w, h) / 2,
    rotation = 0,
    oldrotation = 0,
    picked = 100000,
    oldpick = [],
    color = d3.scale.category20();

  // remove chart each time
  var existingChart = d3.select("svg");
  if (existingChart) {
    existingChart.remove();
  }
  // set chart again with all props until
  var svg = d3
    .select("#chart")
    .append("svg")
    .data([data])
    .attr("width", w + padding.left + padding.right)
    .attr("height", h + padding.top + padding.bottom);
  // create container
  var container = svg
    .append("g")
    .attr("class", "chartholder")
    .attr(
      "transform",
      "translate(" + (w / 2 + padding.left) + "," + (h / 2 + padding.top) + ")"
    );
  var vis = container.append("g");
  // declare chart type
  var pie = d3.layout
    .pie()
    .sort(null)
    .value(function (d) {
      return 1;
    });
  // declare an arc generator function
  var arc = d3.svg.arc().outerRadius(r);
  // select paths, use arc generator to draw
  var arcs = vis
    .selectAll("g.slice")
    .data(pie)
    .enter()
    .append("g")
    .attr("class", "slice");

  arcs
    .append("path")
    .attr("fill", function (d, i) {
      return color(i);
    })
    .attr("d", function (d) {
      return arc(d);
    });
  // add the text
  arcs
    .append("text")
    .attr("transform", function (d) {
      d.innerRadius = 0;
      d.outerRadius = r;
      d.angle = (d.startAngle + d.endAngle) / 2;
      return (
        "rotate(" +
        ((d.angle * 180) / Math.PI - 90) +
        ")translate(" +
        (d.outerRadius - 10) +
        ")"
      );
    })
    .attr("text-anchor", "end")
    .text(function (d, i) {
      return data[i].name;
    });

  //set click handler
  //   container.on("click", spin);

  const spaceSpin = (e) => {
    if (e.keyCode === 32 || e.key == " " || e.code == "Space") {
      spin();
      document.removeEventListener("keydown", spaceSpin);
    }
  };

  document.addEventListener("keydown", spaceSpin);

  // spin the wheel!
  function spin(d) {
    container.on("click", null);

    //all slices have been seen, all done
    oldpick = []; //comment to use blacked out questions
    if (oldpick.length == data.length) {
      container.on("click", null);
      return;
    }
    var ps = 360 / data.length,
      pieslice = Math.round(1440 / data.length),
      rng = Math.floor(Math.random() * 1440 + 360);

    rotation = Math.round(rng / ps) * ps;

    picked = Math.round(data.length - (rotation % 360) / ps);
    picked = picked >= data.length ? picked % data.length : picked;
    if (oldpick.indexOf(picked) !== -1) {
      d3.select(this).call(spin);
      return;
    } else {
      oldpick.push(picked);
    }
    rotation += 90 - Math.round(ps / 2);
    vis
      .transition()
      .duration(4500)
      .attrTween("transform", rotTween)
      .each("end", function () {
        //mark question as seen
        d3.select(".slice:nth-child(" + (picked + 1) + ") path");
        //.attr("fill", "#111"); //uncomment to use blacked out questions
        //populate question
        d3.select("#question h1").text(data[picked].name);
        oldrotation = rotation;

        // HERE THE DATA IS REPOPULATED

        if (isTouched === true) {
          document.getElementById("wrapper").style = "opacity:0.5";
          document.getElementById("modal-result").classList.add("active");
          if (data[picked].hasSurprise === true) {
            document.getElementById("image").style = "visibility:visible; height: 320px";
          }
          setTimeout(function () {
            document.getElementById("wrapper").style = "opacity:1";
            document.getElementById("modal-result").classList.remove("active");
            data = prepareData(tempData);
            isSpinning = false;
            setWheelChart();
          }, 5000);
        }
        // container.on("click", spin);
      });
  }
  //make arrow
  svg
    .append("g")
    .attr(
      "transform",
      "translate(" +
      (w + padding.left + padding.right) +
      "," +
      (h / 2 + padding.top) +
      ")"
    )
    .append("path")
    .attr("d", "M-" + r * 0.15 + ",0L0," + r * 0.05 + "L0,-" + r * 0.05 + "Z")
    .style({ fill: "black" });
  //draw spin circle
  container
    .append("circle")
    .attr("cx", 0)
    .attr("cy", 0)
    .attr("r", 60)
    .style({ fill: "white", cursor: "pointer" });
  //spin text
  container
    .append("text")
    .attr("x", 0)
    .attr("y", 15)
    .attr("text-anchor", "middle")
    .text("Ontdek")
    .style({ "font-weight": "bold", "font-size": "30px" });

  function rotTween(to) {
    var i = d3.interpolate(oldrotation % 360, rotation);
    return function (t) {
      return "rotate(" + i(t) + ")";
    };
  }
}

// function to shuffle data
const shuffle = (array) => {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};
// here the data is prepared once more
const prepareData = (array) => {
  const newArray = [...shuffle([...array, ...prizeData])];
  // if it is long enough or too long make sure it does not exceed the global limit value

  return newArray;
};
